import {
  AspectRatioIconWrapper,
  AudioPlayerBox,
  FileBox,
  FileIconBox,
  FileNameBox,
  FileWrapper,
} from "./styles";
import { SmallText } from "../../../../../utils/GlobalStyles";
import { generateFileTypeIcon } from "../../../../../utils/Functions/CaseFilesHelpers";
import { FileItemProps } from "../../../../../utils/Interfaces/Game";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import ModalFileItem from "./ModalFileItem";
import React from "react";
import { COLORS } from "../../../../../assets/theme";

function FileItem({ file, activeFolder }: FileItemProps) {
  const bucket_url = "https://di7qevpv9xgl3.cloudfront.net/";
  const bucketFolder = `${bucket_url}game/`;
  const fileType = file.split(".").pop();
  const [fileOpen, setFileOpen] = React.useState(false);

  const handleOpenFile = () => {
    if (fileType === "pdf" || fileType === "jpg") {
      setFileOpen(!fileOpen);
    }
  };

  return (
    <>
      <FileWrapper
        key={file}
        onClick={handleOpenFile}
        isPdforImage={fileType === "pdf" || fileType === "jpg"}
      >
        <FileIconBox>{generateFileTypeIcon(fileType || "")}</FileIconBox>
        {fileType === "mp3" ? (
          <AudioPlayerBox>
            <AudioPlayer
              style={{ backgroundColor: COLORS.grayTwo }}
              src={`${bucketFolder}${activeFolder}/${encodeURIComponent(file)}`}
              showSkipControls={false}
              showJumpControls={false}
              loop={false}
              customAdditionalControls={[<></>]}
            />
          </AudioPlayerBox>
        ) : (
          <div style={{ position: "relative" }}>
            {(fileType === "pdf" || fileType === "jpg") && (
              <FileBox className="child-box" onClick={handleOpenFile}>
                {(fileType === "pdf" || fileType === "jpg") && (
                  <button onClick={handleOpenFile}>
                    <AspectRatioIconWrapper />
                  </button>
                )}
              </FileBox>
            )}
            <iframe
              src={`${bucketFolder}${activeFolder}/${encodeURIComponent(file)}#toolbar=0&navpanes=0&scrollbar=0`}
              height="340px"
              width="275px"
              allowFullScreen
            />
          </div>
        )}
        <FileNameBox>
          <SmallText>{file.split(".")[0]}</SmallText>
        </FileNameBox>
      </FileWrapper>
      <ModalFileItem
        file={file}
        activeFolder={activeFolder}
        fileOpen={fileOpen}
        handleOpenFile={handleOpenFile}
      />
    </>
  );
}

export default FileItem;
